import React from "react"
import ProjectsV2 from "../components/ProjectsV2"
import SmallHero from "../components/SmallHero"
import SEO from "../components/SEO"
import Footer from "../components/Footer";

import getProjects from "../queries/getProjects";
import getPageProjects from "../queries/getPageProjects";

export default props => {

    const { projects: { edges: projects } } = getProjects();
    const { data: { frontmatter: projectsToShow } } = getPageProjects();

    return (
        <>
        <SEO pageData={ projectsToShow } />
        <SmallHero title="All our projects" image={ projectsToShow.backgroundImage.childImageSharp.fluid } />
        <ProjectsV2 projects={ projectsToShow.portfolioItems } allProjects={ projects } />
        <Footer />
        </>
    )
};
